export const themeConstant = {
  font: {
    title: '"Roboto", "Helvetica", "Arial", sans-serif', //'Biennale',
    tableHeader: '"Roboto", "Helvetica", "Arial", sans-serif', //'Biennale',
    button: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
    content: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
    menu: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
  },
  loadingBar: {
    color: '#F2E21E',
  },
  chart: {
    colors: ['#E20E19', '#F2E21E', '#0052A0', '#000000', '#283A8D'],
    allSuppliersLineColor: '#909090',
    arrivedOnTimeColors: {
      early: '#0052A0',
      onTime: '#283A8D',
      late: '#F2E21E',
      veryLate: '#E20E19',
    },
    trendLine:{
      lineColor: '#0052A0'
    },
    avgLine:{
      lineColor: '#283A8D'
    }
  },
  table: {
    shipments: {
      arrivedOnTimeColors: {
        late: '#FFB346',
        veryLate: '#FF6962',
        onTime: '#A9D099',
      },
    },
    tag: {
      color: '#283A8D',
    },
    filterTag: {
      color: '#0052A0',
    },
  },
  pdf: {
    table: {
      headerColor: '#254B99',
    },
  },
  shipmentTracking: {
    map: {
      originColor: '#E20E19',
      destinationColor: '#0052A0',
      transhipmentColors: ['#F2E21E', '#000000', '#283A8D'],
      geoLineColor: '#32342F',
      vessel: '#E20E19',
    },
    milestones: {
      lineColor: '#254B99',
      completedText: '#FFFFFF',
    },
  },
};
