import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from '@material-ui/core/Snackbar';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import apiService from '../../../services/api.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import Alert from '../../../wrappers/Alert';
import Button from '../../../wrappers/Button';
import MyButton from '../../../wrappers/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  avatar: {
    height: 200,
    width: 200,
  },
  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  btnCancel: {
    backgroundColor: '#F2E21E',
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const UserEntry = (props) => {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  let themeName = parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark';

  const classes = useStyles();
  const progressRef = useRef(null);

  const [didInit, setDidInit] = useState(false);
  const [profileFile, setProfileFile] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [image, setImage] = useState();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [buyerId, setBuyerId] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [accounts, setAccounts] = useState();
  const [user, setUser] = useState();
  const [isAdministrator, setIsAdministrator] = useState('false');
  const [hasFullPermissions, setHasFullPermissions] = useState(true);
  const [accountIds, setAccountIds] = useState([]);
  const [snackbarError = '', setsnackbarError] = useState();
  const [displayAccountError, setDisplayAccountError] = useState('');
  const [displayFirstnameError, setDsplayFirstnameError] = useState('');
  const [displayLastnameError, setDisplayLastnameError] = useState('');
  const [displayEmailError, setDisplayEmailError] = useState('');
  const [displayPhonenumberError, setDisplayPhonenumberError] = useState('');

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [, setReloadContext] = useContext(ReloadContext);
  const [userContext] = useContext(UserContext);
  const { id } = useParams();
  const history = useHistory();

  const inputFile = useRef();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);

  const [rolesList, setRolesList] = useState([]);

  useEffect(() => {
    if (userContext.isAdmin !== true || !userContext.permissions.canManageUsers) {
      history.push('/users');
    } else {
      initRolesList();
      progressRef.current.continuousStart();
      if (isEdit()) {
        apiService.getUserEntryAccountSelectItems().then((accountResult) => {
          setAccounts(accountResult.data);
          apiService
            .getUser(id)
            .then((userResult) => {
              setUser(userResult.data);
              setFirstname(userResult.data.firstname);
              setLastname(userResult.data.lastname);
              setEmail(userResult.data.username);
              setPhonenumber(userResult.data.phoneNumber);
              setProfileImageSrc(userResult.data.imageUrl);
              setIsAdministrator(userResult.data.isAdministrator.toString());
              setHasFullPermissions(userResult.data.hasFullPermissions);
              setBuyerId(userResult.data.buyerId);
              if (userResult.data.accountIds) setAccountIds(userResult.data.accountIds);
              if (progressRef && progressRef.current) progressRef.current.complete();
              setDidInit(true);
            })
            .catch((err) => {
              console.log('error: ', err);
              if (progressRef && progressRef.current) progressRef.current.complete();
              if (err.response && err.response.status === 401) history.push('/login');
            });
        });
      } else {
        setDidInit(true);
        apiService
          .getUserEntryAccountSelectItems()
          .then((result) => {
            setAccounts(result.data);
            progressRef.current.complete();
          })
          .catch((err) => {
            console.log('error: ', err);
            if (progressRef && progressRef.current) progressRef.current.complete();
            if (err.response && err.response.status === 401) history.push('/login');
          });
      }
    }
  }, []);

  const initRolesList = () => {
    let rolesList = [];
    if ((userContext.isAdmin && userContext.hasFullPermissions) || isEdit()) {
      rolesList.push({ text: 'Administrator', isAdministrator: true });
    }
    rolesList.push({ text: 'Account User', isAdministrator: false });
    setRolesList(rolesList);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setOpenSuccessSnackbar(false);
  };
  const cleanErrors = () => {
    setDsplayFirstnameError('');
    setDisplayLastnameError('');
    setDisplayEmailError('');
    setDisplayPhonenumberError('');
    setDisplayAccountError('');
  };
  const validateError = (errors) => {
    for (let error of errors) {
      if (error.propertyName === 'FirstName') setDsplayFirstnameError(error.errorMessage);
      else if (error.propertyName === 'LastName') setDisplayLastnameError(error.errorMessage);
      else if (error.propertyName === 'Email') setDisplayEmailError(error.errorMessage);
      else if (error.propertyName === 'PhoneNumber') setDisplayPhonenumberError(error.errorMessage);
      else if (error.propertyName === 'AccountId') setDisplayAccountError(error.errorMessage);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    cleanErrors();
    const formData = new FormData();
    formData.append('avatar', image);
    let accountIdsString = '';
    if (accountIds && accountIds.length > 0) {
      accountIdsString = accountIds.join(', ');
    }

    if (!isEdit()) {
      apiService
        .createUser(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            hasImage: hasImage(),
            isAdministrator: isAdministrator,
            hasFullPermissions: hasFullPermissions,
            firstname: firstname,
            phoneNumber: phonenumber,
            lastname: lastname,
            email: email,
            accountIds: accountIdsString,
            buyerId: buyerId,
          },
        })
        .then((result) => {
          progressRef.current.complete();
          if (result.errors && result.errors.length > 0) {
            validateError(result.errors);
            var errorString = '';
            for (var i = 0; i < result.errors.length; i++) {
              if (result.errors[i].errorMessage) {
                errorString += ' ' + result.errors[i].errorMessage + ',';
              } else errorString += ' ' + result.errors[i] + ',';
            }
            setsnackbarError(errorString);
            setOpenSnackbar(true);
          } else {
            setDisableSaveButton(true);
            setOpenSuccessSnackbar(true);
            setReloadContext(new Date());
          }
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error', err);
          setsnackbarError(err);
          setOpenSnackbar(true);
        });
    } else {
      apiService
        .updateUser(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            id: user.appId,
            hasImage: hasImage(),
            isAdministrator: isAdministrator,
            hasFullPermissions: hasFullPermissions,
            firstname: firstname,
            phoneNumber: phonenumber,
            lastname: lastname,
            email: email,
            accountIds: accountIdsString,
            buyerId: buyerId,
          },
        })
        .then((result) => {
          progressRef.current.complete();
          if (result.errors && result.errors.length > 0) {
            validateError(result.errors);
            var errorString = '';
            for (var i = 0; i < result.errors.length; i++) {
              if (result.errors[i].errorMessage) {
                errorString += ' ' + result.errors[i].errorMessage;
              } else errorString += ' ' + result.errors[i];
            }
            setsnackbarError(errorString);
            setOpenSnackbar(true);
          } else {
            setDisableSaveButton(true);
            setReloadContext(new Date());
            setOpenSuccessSnackbar(true);
          }
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          setsnackbarError(err);
          setOpenSnackbar(true);
        });
    }
    setReloadContext(new Date());
  };
  const handleRoleChange = (e) => {
    setIsAdministrator(e.target.value);
    if (isAdministrator === 'false') {
      //set accountid null here
      setAccountIds([]);
    }
  };
  const handleHasFullPermissionsChange = (e) => {
    setHasFullPermissions(e.target.checked);
    setAccountIds([]);
  };
  const handleAccountIdsChange = (event) => {
    let tempAccountIds = [...accountIds];
    if (event.target.checked) {
      tempAccountIds.push(event.target.name);
    } else {
      var index = tempAccountIds.indexOf(event.target.name);
      if (index !== -1) {
        tempAccountIds.splice(index, 1);
      }
    }
    setAccountIds(tempAccountIds);
  };
  const uploadSingleFile = (e) => {
    let inputFile = e.target.files[0];
    if (inputFile && inputFile.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (inputFile.size > 150000) {
        if (inputFile && inputFile.current) inputFile.current.value = '';
        setErrorAlertOpen(true);
      } else {
        setImage(e.target.files[0]);
        setErrorAlertOpen(false);
        setProfileFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };
  const isEdit = () => {
    return id ? true : false;
  };
  const hasImage = () => {
    if (image || profileImageSrc) return true;
    else return false;
  };
  const canManageRoleAndPermissions = () => {
    if (isEdit()) {
      return userContext.isAdmin && userContext.hasFullPermissions;
    } else {
      return userContext.isAdmin;
    }
  };
  const canManageUserInformation = () => {
    if (isEdit()) {
      if (userContext.isAdmin) {
        if (userContext.hasFullPermissions) {
          return true;
        } else {
          return isAdministrator === 'false';
        }
      } else {
        return false;
      }
    } else {
      return userContext.isAdmin;
    }
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={theme}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          The user was successfully {isEdit() ? 'updated' : 'created'}
        </Alert>
      </Collapse>
      <Collapse in={errorAlertOpen}>
        <Alert
          theme={theme}
          severity="error"
          onClose={() => {
            setErrorAlertOpen(false);
          }}
          className={classes.alert}
        >
          The image cannot have a size greater than 150kb
        </Alert>
      </Collapse>
      <div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {snackbarError}
          </Alert>
        </Snackbar>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            The user was successfully {isEdit() ? 'updated' : 'created'}
          </Alert>
        </Snackbar>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {/* Profile Image */}
          <Grid style={{ display: 'flex' }} container item md={4} xs={12} spacing={2}>
            <Grid item xs={12}>
              <Card className={clsx(classes.root, classes.card)}>
                {didInit && canManageUserInformation() && (
                  <div className={classes.rightTopCorner}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setProfileFile();
                        setProfileImageSrc();
                        setImage();
                      }}
                    >
                      <DeleteIcon size="small" className={classes.actionButtonDelete} />
                    </IconButton>
                  </div>
                )}

                <CardContent>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <Avatar className={classes.avatar} src={profileFile ? profileFile : profileImageSrc ? profileImageSrc : ''} />
                  </Box>
                </CardContent>
                <Divider />
                {didInit && canManageUserInformation() && (
                  <CardActions>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      single="true"
                      ref={inputFile}
                      onChange={uploadSingleFile}
                      type="file"
                    />
                    <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                      <Button
                        theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'}
                        style={{ width: '100%', marginTop: '10px' }}
                        component="span"
                      >
                        Change Image
                      </Button>
                    </label>
                  </CardActions>
                )}
              </Card>
              {didInit && canManageUserInformation() && isEdit() && (
                <Button style={{ marginTop: '14px' }} fullWidth onClick={() => history.push(`/users/${id}/set-password`)} theme={theme}>
                  Set Password
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item md={8} xs={12} container spacing={2}>
            {/* Roles */}
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl required fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="role-select">Role</InputLabel>
                        <Select
                          native
                          value={isAdministrator || ''}
                          onChange={handleRoleChange}
                          inputProps={{
                            name: 'role',
                            id: 'role-select',
                          }}
                          disabled={!canManageRoleAndPermissions()}
                        >
                          {rolesList &&
                            rolesList.map((item, index) => {
                              return (
                                <option key={index} value={item.isAdministrator}>
                                  {item.text || ''}
                                </option>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {isAdministrator === 'true' ? (
                      <>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hasFullPermissions}
                                onChange={handleHasFullPermissionsChange}
                                name="hasFullPermissions"
                                disabled={!canManageRoleAndPermissions()}
                              />
                            }
                            label="Has full permissions on the system"
                          />
                        </Grid>
                        {!hasFullPermissions && (
                          <Grid item xs={12}>
                            <FormControl component="fieldset" className={classes.formControl}>
                              <FormLabel component="legend">Assign accounts to this user</FormLabel>
                              <FormGroup>
                                {accounts &&
                                  accounts.map((item) => {
                                    return (
                                      <FormControlLabel
                                        key={'admin-' + item.id}
                                        control={
                                          <Checkbox name={item.id} checked={accountIds && accountIds.indexOf(item.id) > -1} onChange={handleAccountIdsChange} />
                                        }
                                        label={item.name}
                                        disabled={!canManageRoleAndPermissions()}
                                      />
                                    );
                                  })}
                              </FormGroup>
                              <FormHelperText>This administrator can manage the users in selected accounts.</FormHelperText>
                            </FormControl>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <FormControl
                          required
                          fullWidth
                          className={classes.formControl}
                          disabled={!canManageRoleAndPermissions()}
                          error={!(displayAccountError === '')}
                        >
                          <InputLabel htmlFor="account-select">Account</InputLabel>
                          <Select
                            native
                            value={accountIds && accountIds.length > 0 ? accountIds[0] : ''}
                            onChange={(e) => setAccountIds([e.target.value])}
                            inputProps={{
                              name: 'account',
                              id: 'account-select',
                            }}
                          >
                            <option aria-label="None" value="" />
                            {accounts &&
                              accounts.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name || ''}
                                  </option>
                                );
                              })}
                          </Select>
                          <FormHelperText>{displayAccountError === '' ? '' : displayAccountError}</FormHelperText>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* User Information */}
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Username (Email)"
                      margin="dense"
                      type="email"
                      name="email"
                      error={!(displayEmailError === '')}
                      helperText={displayEmailError === '' ? '' : displayEmailError}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email || ''}
                      disabled={isEdit()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Firstname"
                      margin="dense"
                      name="firstname"
                      error={!(displayFirstnameError === '')}
                      helperText={displayFirstnameError === '' ? '' : displayFirstnameError}
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname || ''}
                      disabled={!canManageUserInformation()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Lastname"
                      margin="dense"
                      name="lastname"
                      error={!(displayLastnameError === '')}
                      helperText={displayLastnameError === '' ? '' : displayLastnameError}
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname || ''}
                      disabled={!canManageUserInformation()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      name="phonenumber"
                      error={!(displayPhonenumberError === '')}
                      helperText={displayPhonenumberError === '' ? '' : displayPhonenumberError}
                      onChange={(e) => setPhonenumber(e.target.value)}
                      value={phonenumber || ''}
                      disabled={!canManageUserInformation()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Buyer Id"
                      margin="dense"
                      name="buyerId"
                      onChange={(e) => setBuyerId(e.target.value)}
                      value={buyerId || ''}
                      disabled={!canManageUserInformation()}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Buttons */}
            {didInit && (
              <Grid item xs={12}>
                {canManageUserInformation() ? (
                  <Box className={classes.buttonsBox}>
                    <MyButton theme={themeName} type="submit" disabled={disableSaveButton} className={classes.buttons} buttonType="primary">
                      Save
                    </MyButton>
                    <MyButton
                      theme={themeName}
                      className={clsx(classes.buttons, classes.buttonRight)}
                      buttonType="cancel"
                      onClick={() => props.history.goBack()}
                    >
                      Cancel
                    </MyButton>
                  </Box>
                ) : (
                  <Box className={classes.buttonsBox}>
                    <MyButton
                      theme={themeName}
                      className={clsx(classes.buttons, classes.buttonRight)}
                      buttonType="cancel"
                      onClick={() => props.history.goBack()}
                    >
                      Back
                    </MyButton>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UserEntry;
